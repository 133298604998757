export default class ContextMenu {
    constructor($el) {
        this.el = $el;
        this.menu = $el.querySelector('.context-menu__menu');
        this.openBtn = $el.querySelector('.context-menu__btn');
        this.closeBtn = $el.querySelector('.context-menu__close-bg');

        this.position = $el.dataset.tooltipPosition ?? 'left';

        this.setContextMenuMaxWidth();

        this.openBtn.addEventListener( 'mouseenter', () => {
            if ( window.innerWidth < 768 ) return;

            this.open();
        } );

        this.openBtn.addEventListener( 'mouseleave', () => {
            if ( window.innerWidth < 768 ) return;

            this.close();
        } );

        this.openBtn.addEventListener( 'focusin', () => {
            if ( window.innerWidth < 768 ) return;
            
            this.open();
        } );

        this.openBtn.addEventListener( 'focusout', () => {
            if ( window.innerWidth < 768 ) return;
            
            this.close();
        } );

        this.openBtn.addEventListener( 'click', () => {
            if ( window.innerWidth >= 768 ) return;
            
            this.open();
        } );

        if ( this.closeBtn ) {
            this.closeBtn.addEventListener( 'click', () => {
                this.close();
            } );
        }

        window.addEventListener( 'resize', () => {
            this.onResize();
        } );
    }

    onResize() {
        this.setContextMenuMaxWidth();
    }

    setContextMenuMaxWidth() {
        this.menu.style.maxWidth = '';

        if ( window.innerWidth < 768 ) return;

        window.requestAnimationFrame( () => {
            const width = this.position === 'left' ? document.body.clientWidth - this.menu.getBoundingClientRect().x : this.menu.getBoundingClientRect().x + this.menu.offsetWidth;
            this.menu.style.maxWidth = `${width - 9}px`;
        } );
    }

    open() {
        this.menu.classList.add('active');

        if ( this.closeBtn ) this.closeBtn.classList.add('active');
    }

    close() {
        this.menu.classList.remove('active');
        
        if ( this.closeBtn ) this.closeBtn.classList.remove('active');
    }
}
