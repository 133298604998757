import Tooltip from '../classes/Tooltip';

( () => {
    const $tooltips = document.querySelectorAll('.tooltip');

    if ( !$tooltips ) return;


    $tooltips.forEach( $tooltip => {
        new Tooltip($tooltip);
    } );

} )();
