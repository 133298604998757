import '../images/logo.svg';
import '../images/logo-ireland.svg';
import '../images/icon-chevron-up-dark-blue.svg';
import '../images/icon-chevron-down-dark-blue.svg';
import '../images/icon-chevron-right-white.svg';
import '../images/icon-chevron-right-blue.svg';
import '../images/icon-comment-add-gold.svg';
import '../images/icon-comment-query-gold.svg';
import '../images/icon-document-add-gold.svg';
import '../images/icon-document-gold.svg';
import '../images/icon-edit-gold.svg';
import '../images/icon-media-add-gold.svg';
import '../images/icon-media-gold.svg';
import '../images/icon-actions-dark-blue.svg';
import '../images/icon-check-gold.svg';
import '../images/icon-check-green.svg';
import '../images/icon-plus-gold.svg';
import '../images/icon-plus-red.svg';
import '../images/icon-warning-red.svg';
import '../images/icon-rejected-red.svg';
import '../images/icon-alert-red.svg';
import '../images/icon-alert-yellow.svg';

import '../images/icon-facebook.svg';
import '../images/icon-twitter.svg';
import '../images/icon-instagram.svg';

import '../sass/main.scss';

import './generic/focus';
import './generic/modals';

import './components/siteNav';

import './components/key';
import './components/tooltip';
import './components/contextMenu';
import './components/forms';
import './components/postcodeLookup';
import './components/nominationsTable';
