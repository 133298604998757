import ContextMenu from '../classes/ContextMenu';

( () => {

    const $contextMenus = document.querySelectorAll('.context-menu');

    if ( !$contextMenus ) return;

    $contextMenus.forEach( $contextMenu => {
        new ContextMenu($contextMenu);
    } );

} )();
