const enableBodyScroll = () => {
    document.body.classList.remove('no-scroll');
    document.body.style.width = '';
};

const disableBodyScroll = () => {
    document.body.style.width = document.body.clientWidth + 'px';
    document.body.classList.add('no-scroll');
};

const setModalWidths = () => {
    const $activeModals = document.querySelectorAll('.modal.active, .modal-resize.active');
    const $fixedEls = document.querySelectorAll('.fixed-el');

    let hasActiveModal = false;

    if ( $activeModals.length ) {
        $activeModals.forEach( $modal => {
            if ( !$modal.dataset?.modalMaxWidth || window.innerWidth < $modal.dataset.modalMaxWidth ) {
                hasActiveModal = true;
            }
        } );
    }

    if ( !hasActiveModal ) {
        enableBodyScroll();
        
        if ( $fixedEls.length ) {
            $fixedEls.forEach( $fixedEl => {
                $fixedEl.style.width = '';
            } );
        }

        if ( $activeModals.length ) {
            $activeModals.forEach( $modal => {
                if ( $modal.dataset?.modalMaxWidth && window.innerWidth >= $modal.dataset.modalMaxWidth ) {
                    closeModal($modal);
                }
            } );
        }

        return;
    } else {
        enableBodyScroll();

        if ( $fixedEls.length ) {
            $fixedEls.forEach( $fixedEl => {
                $fixedEl.style.width = document.body.clientWidth + 'px';
            } );
        }

        if ( $activeModals.length ) {
            $activeModals.forEach( $modal => {
                if ( !$modal.dataset?.modalMaxWidth || window.innerWidth < $modal.dataset.modalMaxWidth ) {
                    $modal.style.width = document.body.clientWidth + 'px';
                }
            } );
        }

        disableBodyScroll();
    }
};

export function closeModal($modal) {
    const $modalToggleBtns = $modal.dataset?.modalToggleBtn ? document.querySelectorAll($modal.dataset.modalToggleBtn) : false;

    $modal.style.width = '';

    $modal.classList.remove('active');

    if ( $modalToggleBtns.length ) {
        $modalToggleBtns.forEach( $modalToggleBtn => {
            $modalToggleBtn.classList.remove('active');
            $modalToggleBtn.ariaExpanded = false;
        } );
    }

    setModalWidths();
}

export function openModal($modal) {
    const $modalToggleBtns = $modal.dataset?.modalToggleBtn ? document.querySelectorAll($modal.dataset.modalToggleBtn) : false;

    $modal.style.width = $modal.offsetWidth + 'px';

    disableBodyScroll();

    $modal.classList.add('active');

    if ( $modalToggleBtns.length ) {
        $modalToggleBtns.forEach( $modalToggleBtn => {
            $modalToggleBtn.classList.add('active');
            $modalToggleBtn.ariaExpanded = true;
        } );
    }

    setModalWidths();
};

( () => {
    const $modalToggleBtns = document.querySelectorAll('.modal-open, .modal-close');
    const $modalResize = document.querySelectorAll('.modal, .modal-resize');

    if ( $modalToggleBtns.length ) {
        $modalToggleBtns.forEach( $modalToggleBtn => {
            const $modal = $modalToggleBtn.dataset?.modalTarget ? document.getElementById($modalToggleBtn.dataset.modalTarget) : false;

            if ( !$modal ) return;

            $modalToggleBtn.addEventListener( 'click', () => {
                if ( $modal.classList.contains('active') && $modalToggleBtn.classList.contains('modal-close') ) {
                    closeModal($modal);
                } else if ( !$modal.classList.contains('active') && $modalToggleBtn.classList.contains('modal-open') ) {
                    openModal($modal);
                }
            } );
        } );
    }

    if ( $modalResize.length ) {
        window.addEventListener( 'resize', setModalWidths );
    }

    setModalWidths();
} )();
