import Key from '../classes/Key';

( () => {

    const $keys = document.querySelectorAll('.key');

    if ( !$keys ) return;

    $keys.forEach( $key => {
        new Key($key);
    } );

} )();
