export default class Tooltip {
    constructor($el) {
        this.el = $el;

        this.position = $el.dataset.tooltipPosition ?? 'left';
        
        this.tooltipText = document.createElement('div');
        this.tooltipText.className = `tooltip__text ${this.position}`;
        this.tooltipText.textContent = $el.title;

        $el.title = '';

        $el.appendChild(this.tooltipText);

        this.setTooltipMaxWidth();

        window.addEventListener('resize', () => {
            this.onResize();
        } );
    }

    onResize() {
        this.setTooltipMaxWidth();
    }

    setTooltipMaxWidth() {
        this.tooltipText.style.maxWidth = '';

        window.requestAnimationFrame( () => {
            const width = this.position === 'left' ? document.body.clientWidth - this.tooltipText.getBoundingClientRect().x : this.tooltipText.getBoundingClientRect().x + this.tooltipText.offsetWidth;
            this.tooltipText.style.maxWidth = `${width - 9}px`;
        } );
    }
}
