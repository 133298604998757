import Pristine from 'pristinejs';

( () => {

    const $forms = document.querySelectorAll('.form');

    if ( !$forms ) return;

    $forms.forEach( $form => {
        const validateForm = () => {
            // create the pristine instance
            const pristine = new Pristine($form);
        
            $form.addEventListener( 'submit', e => {
                if ( !pristine.validate() ) {
                    e.preventDefault();
                }
            } );
        };

        window.addEventListener( 'load', validateForm );
    } );

} )();