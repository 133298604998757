import axios from "axios";

( () => {

    const $postcodeLookups = document.querySelectorAll('.postcode-lookup');

    if ( !$postcodeLookups ) return;

    $postcodeLookups.forEach( $postcodeLookup => {

        const $postcodeInputWrap = $postcodeLookup.querySelector('.postcode-lookup__input-wrap');
        const $postcodeInput = $postcodeLookup.querySelector('.postcode-lookup__input');
        const $searchBtn = $postcodeLookup.querySelector('.postcode-lookup__search-btn');
        const $addressSelectWrap = $postcodeLookup.querySelector('.postcode-lookup__address-select-wrap');
        const $addressSelect = $postcodeLookup.querySelector('.postcode-lookup__address-select');
        const $addressInputs = $postcodeLookup.querySelector('.postcode-lookup__address-inputs');
        const $addressLine1 = $postcodeLookup.querySelector('.postcode-lookup__address-line-1');
        const $addressLine2 = $postcodeLookup.querySelector('.postcode-lookup__address-line-2');
        const $addressLine3 = $postcodeLookup.querySelector('.postcode-lookup__address-line-3');
        const $addressLine4 = $postcodeLookup.querySelector('.postcode-lookup__address-line-4');
        const $addressLine5 = $postcodeLookup.querySelector('.postcode-lookup__address-line-5');
        const $manualBtnWrap = $postcodeLookup.querySelector('.postcode-lookup__manual-btn-wrap');
        const $manualBtn = $postcodeLookup.querySelector('.postcode-lookup__manual-btn');

        const showAddressInputs = () => {
            $addressInputs.classList.add('show');

            $addressInputs.style.height = `${$addressInputs.offsetHeight}px`;

            $manualBtnWrap.style.height = `${$manualBtnWrap.offsetHeight}px`;

            window.requestAnimationFrame( () => {
                $addressInputs.style.height = `${$addressInputs.scrollHeight}px`;
                $addressInputs.classList.add('active');

                $manualBtnWrap.style.height = '0';
                $manualBtnWrap.classList.add('hide');

                setTimeout( () => {
                    $addressInputs.style.height = '';
                }, 500);
            } );
        }

        const postcodeLookup = () => {
            const postcode = $postcodeInput.value;

            $postcodeInputWrap.classList.add('loading');

            axios.get(`https://api.getAddress.io/find/${postcode}`, {
                    params: {
                        'api-key': 'ETt3dJSiD06vyAaAFlEVcA31536',
                        'format': true,
                    }
                } ).then( response => {
                    if ( response.status === 200 ) {
                        const { addresses } = response.data;

                        if ( !addresses ) return;
    
                        addresses.forEach( ( address, index ) => {
    
                            const option = document.createElement('option');
                            option.text = address.filter( addressPart => addressPart !== '' ).join(', ');
                            option.value = index;
    
                            $addressSelect.add(option);
    
                        } );

                        $addressSelectWrap.classList.add('show');

                        $addressSelectWrap.style.height = `${$addressSelectWrap.offsetHeight}px`;

                        window.requestAnimationFrame( () => {
                            $addressSelectWrap.style.height = `${$addressSelectWrap.scrollHeight}px`;
                            $addressSelectWrap.classList.add('active');

                            setTimeout( () => {
                                $addressSelectWrap.style.height = '';
                            }, 500);
                        } );
                    
                        $addressSelect.addEventListener( 'change', e => {
                            console.log(e);
    
                            const address = addresses[parseInt(e.target.value)];

                            if ( !address ) return;
                            
                            $addressLine1.value = address[0];
                            $addressLine2.value = address[1];
                            $addressLine3.value = address[2];
                            $addressLine4.value = address[3];
                            $addressLine5.value = address[4];
    
                            showAddressInputs();
                        } );
                    }
                } ).catch( error => {
                    console.log(error.response);
                } ).finally( () => {
                    $postcodeInputWrap.classList.remove('loading');
                } );
        };

        $searchBtn.addEventListener( 'click', () => {
            postcodeLookup();
        } )

        $postcodeInput.addEventListener( 'keyup', e => {
            if (e.keyCode == 13) {
                e.preventDefault();
                
                postcodeLookup();
            }
        } );

        $manualBtn.addEventListener( 'click', () => {
            showAddressInputs();
        } );

    } );

} )();
