export default class Key {
    constructor($el) {
        this.el = $el;
        this.summary = $el.querySelector('summary');

        this.animating = false;

        this.summary.addEventListener( 'click', (e) => this.onClick(e) );
    }

    onClick(e) {
        e.preventDefault();

        if ( this.animating ) return;

        if ( this.el.open ) {
            this.close();
        } else {
            this.open();
        }
    }

    open() {
        this.animating = true;

        this.el.style.height = `${this.summary.offsetHeight}px`;
        this.el.open = true;

        window.requestAnimationFrame( () => {
            this.el.style.height = `${this.el.scrollHeight}px`;
            this.el.classList.add('active');

            setTimeout( () => {
                this.el.style.height = '';
                this.animating = false;
            }, 500);
        } );
    }

    close() {
        this.animating = true;

        this.el.style.height = `${this.el.offsetHeight}px`;

        window.requestAnimationFrame( () => {
            this.el.style.height = `${this.summary.offsetHeight}px`;
            this.el.classList.remove('active');

            setTimeout( () => {
                this.el.style.height = '';
                this.el.open = false;
                this.animating = false;
            }, 500);
        } );
    }
}
