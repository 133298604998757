( () => {
    const initSiteNavDropdowns = () => {
        const $subMenuWraps = document.querySelectorAll('.has-sub-menu');

        if ( $subMenuWraps.length ) {
            $subMenuWraps.forEach( $subMenuWrap => {
                const $subMenuWrapBtn = $subMenuWrap.querySelector('a');
                const $subMenu = $subMenuWrap.querySelector('.sub-menu');

                const showSubMenu = () => {
                    $subMenu.style.height = `${$subMenu.scrollHeight}px`;
                    $subMenuWrap.classList.add('active');

                    setTimeout( () => {
                        $subMenu.style.height = '';
                    }, 250 );
                };

                const hideSubMenu = () => {
                    $subMenu.style.height = `${$subMenu.scrollHeight}px`;
                    $subMenuWrap.classList.remove('active');

                    setTimeout( () => {
                        $subMenu.style.height = '';
                    }, 10 );
                };

                $subMenuWrapBtn.addEventListener('click', e => {
                    e.preventDefault();

                    $subMenuWrapBtn.removeEventListener('mouseleave', () => {
                        hideSubMenu();
                    } );

                    if ( $subMenuWrap.classList.contains('active') ) {
                        hideSubMenu();
                    } else {
                        showSubMenu();
                    }
                } );
            } );
        }
    };

    initSiteNavDropdowns();
} )();
