( () => {

    const $nominationsTables = document.querySelectorAll('.nominations-table');

    if ( !$nominationsTables.length ) return;

    $nominationsTables.forEach( $nominationsTable => {
        
        const $tableBody = $nominationsTable.querySelector('tbody');
        const $addBtn = $nominationsTable.querySelector('.nominations-table__add');

        $addBtn.addEventListener( 'click', () => {
            const insertHtml = ''

            $tableBody.insertAdjacentHTML('beforeend', 
                `<tr>
                    <td class="table-no-top-bottom-border-cell">
                        <div class="input-group">
                            <div class="input-wrap form-group">
                                <label>
                                    <div class="label-text">Name of dam</div>
                                    <div class="input">
                                        <input type="text" placeholder="Search for dam" />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </td>
                    <td class="table-no-top-bottom-border-cell">
                        <div class="input-group">
                            <div class="input-wrap form-group">
                                <label>
                                    <div class="label-text">Nominated horse</div>
                                    <div class="input">
                                        <input type="text" placeholder="Pick dam’s progeny; year, sex, by sire" />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </td>
                </tr>`
            );
        } );

    } );

} )();
